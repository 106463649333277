import React, { Suspense, useEffect, useMemo, useState } from "react"
import * as auctionBackend from '../build/index.main.mjs'
import * as reverseBackend from '../build/index.main.mjs'
import {
  useHistory,
} from "react-router-dom";
import {
  getAccountTransaction,
  getAccInfo,
  fetchEscrow,
  getExplorer,
  formatCompactAddress,
  accountHasAsset,
  getAppInfo,
  getAppUrl as getAppUrlHelper,
  getAppSetupUrl as getAppSetupUrlHelper,
  getAppTransactions,
  fetchApp,
  fetchState,
} from '../functions'
import { Badge, Col, Dropdown, Image, Nav, NavDropdown, Row, Spinner, Table } from "react-bootstrap";
import Button from '@mui/material/Button';
import CardLoader from "../loaders/CardLoader";
import BrowseCard from '../components/BrowseCard'
import PlaceholderCard from '../components/PlaceholderCard'
import RefreshIcon from '@mui/icons-material/Refresh';
import BasicModal from "../components/BasicModal";
import moment from "moment";
import Icon from "react-crypto-icons";
import md5 from "blueimp-md5";
import ContractLoader from "../loaders/ContractLoader";
import AuctionLoader from "../loaders/AuctionLoader";
import auctionService from "../service/auctionService";
import offerService from "../service/offerService";
import reverseService from "../service/reverseService";
import GavelIcon from '@mui/icons-material/Gavel';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import JoinButton from '../components/buttons/JoinButton';
import BuyButton from "../components/buttons/BuyButton";
import AddIcon from '@mui/icons-material/Add';
import appService from "../service/appService.js";
import { Loadable } from "react-loadable";
import LazyLoad from "react-lazyload";
import BidButton from "../components/buttons/BidButton.js";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import tokenService from "../service/tokenService.js";
const auctionStyle =
{
  //"margin": "auto",
  "paddingBottom": "100px"
}
const headingStyle =
{
  "height": "38px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "900",
  "fontSize": "32px",
  "lineHeight": "38px",
  "textAlign": "center",
  "letterSpacing": "0.1em",
  "textTransform": "uppercase",
  "color": "#2A3035",
  "opacity": "0.8",
}
const auctionTitleStyle =
{
  "height": "38px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "900",
  "fontSize": "32px",
  "lineHeight": "38px",
  "textAlign": "center",
  "letterSpacing": "0.1em",
  "textTransform": "uppercase",
  "color": "#2A3035",
  "opacity": "0.8",
}
const Browse = (props) => {
  var lz = require('lz-string');
  const history = useHistory()
  const WAValidator = require('@swyftx/api-crypto-address-validator')
  document.title = "Auction - NFT Jam"
  const {
    stdlib,
    acc,
    approvalProgram,
    reverseApprovalProgram,
    tokenApprovalProgram,
    firstBlock,
    ADDR_PLATFORM,
    ADDR_DISCOVERY,
    FIRSTBLOCK,
    providerEnv,
    feature,
  } = props
  const [app, setApp] = useState(null)
  const [apps, setApps] = useState(null)
  const [apps2, setApps2] = useState(null) // constructor apps
  const [apps3, setApps3] = useState(null) // constructor apps w/ addr
  const [apps4, setApps4] = useState(JSON.parse(localStorage.getItem('apps'))) // constructor apps w/ app state
  const [loaded, setLoaded] = useState(false)
  const [next, setNext] = useState(null)
  const [state, setState] = useState({
    filter: (el) => el.status !== 'open'
  })
  const [showModal, setShowModal] = useState(false)
  const [addLoading, setAddLoading] = useState(false)
  const explorer = getExplorer()
  const handleBuy = async (acc, backend, appId, assetId, reservePrice, onSuccess) => {
    if (!acc) return
    try {
      let hasAsset = await accountHasAsset(acc.address, assetId)
      let ctc = await acc.contract(backend, appId)
      let a = ctc.apis.Bid
      if (!hasAsset) {
        await acc.tokenAccept(assetId)
      }
      await a.touch(null)
      await a.getPurchase(stdlib.parseCurrency(reservePrice))
    } catch (e) {
      console.log(e)
    }
  }
  const filterOpen = ({ status }) => (status === "open")
  const filterForSale = ({ status, app }) => (now => status === "live" && now > moment.unix(parseInt(app?.endSecs)).unix())(moment().unix())
  const getApps =
    //useMemo(() => async (addr, block) => {
    async (addr, block) => {
      let nextToken
      let currentRound
      let auctions = []
      do {
        let params = {
          //"min-round": firstBlock,
          //"min-round": FIRSTBLOCK,
          "min-round": block,
          "tx-type": "pay",
          "limit": 20,
          "next": nextToken
        }
        let res = await getAccountTransaction(addr, params)
        nextToken = (res?.data ?? {})['next-token']
        currentRound = (res?.data ?? {})['current-round']
        let transactions = Array(...(res?.data?.transactions ?? []))
        let senders = transactions.map(el => el?.sender)
        let accounts = (await Promise.all(senders.map(el => getAccInfo(el)))).map(el => el?.data)
        //console.log({res, transactions, senders, accounts})
        // TODO: save min conirmed round as last block 
        let live = await Promise.all(accounts.map(el => fetchEscrow(stdlib, acc, el.address)))
        console.log({ live, nextToken })
        auctions.push(live)
        //} while (nextToken !== undefined)
      } while (0)
      // TODO: add seek end later
      let all = auctions.flatMap(el => el)
      localStorage.setItem('firstblock', currentRound)
      return all
      //}, [ADDR_DISCOVERY, firstBlock])
    }
  /*
  useEffect(() => {
    if (apps2) return
    console.log("Getting apps ...");
    (async () => {
      let apps = [];
      let addrs = await appService.get();
      for (let i in addrs) {
        let addr = addrs[i];
        let accInfo = (await getAccInfo(addr))?.data
        let createdApps = accInfo['created-apps'].map(el => ({
          id: el.id,
          program: md5(el?.params['approval-program'])
        }))
        if (createdApps.length === 0) break
        for (let j in createdApps) {
          let { id, approvalProgram } = createdApps[j]
          //let txs = await getAppTransactions(id)
          //console.log({txs})
        }
        console.log({ accInfo })
        console.log({createdApps})
        apps.push(createdApps)
      }
      return apps
    })()
      .then(apps => apps.flatMap(el => el))
      .then(setApps2)
  }, [apps2])
  useEffect(async () => {
    if (!apps2) return
    let apps3 = []
    for (let i in apps2) {
      let { id, program } = apps2[i]
      let key = `app-${id}`
      let storedAddr = localStorage.getItem(key)
      if (false && storedAddr) {
        apps3.push(JSON.parse(storedAddr))
        continue
      }
      await (new Promise(resolve => setTimeout(resolve, 1000)))
      let app = await fetchApp(id)
      localStorage.setItem(key, JSON.stringify({
        ...app,
        program
      }))
    }
    console.log(apps3)
    setApps3(apps3)
  }, [apps2])
  useEffect(async () => {
    if (!apps3) return
    let apps4 = []
    for (let i in apps3) {
      let app = apps3[i]
      console.log(app)
      await (new Promise(resolve => setTimeout(resolve, 2000)))
      let res = await fetchState(app)
      apps4.push(res)
    }
    localStorage.setItem('apps', JSON.stringify(apps4))
    setApps4(apps4)
  }, [apps3])
  */
  useEffect(async () => {
    if (apps) return
    let accounts = await appService.get()
    let liveAppIds = []
    for (let j in accounts) {
      const [, addrs] = accounts[j]
      for (let i in addrs) {
        let addr = addrs[i]
        console.log({ addr })
        try {
          let createdApps = ((await getAccInfo(addr))?.data ?? {})['created-apps']
          liveAppIds.push(createdApps.map(el => el.id))
        } catch (e) { }
      }
    }
    liveAppIds = liveAppIds.flatMap(el => el)
    console.log(liveAppIds)
    let arr = []
    let auctions
    auctions = await auctionService.get()
    arr.push(
      auctions
        .filter(el => liveAppIds.includes(el.appId))
        .map(el => ({
          ...el,
          type: "auction",
          status: !el.asset ? "open" : "live"
        })))
    auctions = await reverseService.get()
    arr.push(
      auctions
        .filter(el => liveAppIds.includes(el.appId))
        .map(el => ({
          ...el,
          type: "reverse",
          status: !el.asset ? "open" : "live"
        })))
    auctions = await tokenService.get()
    arr.push(
      auctions
        .filter(el => liveAppIds.includes(el.appId))
        .map(el => ({
          ...el,
          type: "token",
          status: !el.asset ? "open" : "live"
        })))
        console.log(arr)
    setApps(arr.flatMap(el => el))
  }, [apps])
  useEffect(() => {
    console.log(apps)
  })

  // update apps every 10 minutes
  /*
  useEffect(() => {
    if (!apps) return
    let interval = setInterval(() => {
      Promise.all(apps.map(({ appId }) =>
        getAppInfo(appId)))
        .catch(handleMore)
    }, 5 * 1000)
    return () => clearInterval(interval)
  }, [apps])
  */

  const fetchNext = async (firstBlock) => {
    //const firstBlock = apps.filter(({ status }) => status === "live")[0].transactions[0]['confirmed-round']
    const storedFirstblock = localStorage.getItem('firstBlock') || FIRSTBLOCK
    //const firstBlock = apps[0].transactions[0]['confirmed-round']
    //const firstBlock = storedFirstblock
    let res = await getApps(ADDR_DISCOVERY, firstBlock, approvalProgram)
    return res
  }

  const handleGalleryClick = (id) => {
    history.push(`/auction/${id}`)
  }

  const handleMore = async () => {
    localStorage.setItem('firstblock', FIRSTBLOCK)
    localStorage.removeItem('firstblock')
    localStorage.removeItem('auction')
    localStorage.removeItem('auction-integrity')
    localStorage.removeItem('live')
    localStorage.removeItem('live-integrity')
    localStorage.removeItem('closed')
    localStorage.removeItem('closed-integrity')
    setState({ ...state, filter: (el) => el.status !== 'open' })
    setApps(null)
  }

  const handleCardClick = (escrow) => {
    console.log("CARD CLICK")
    console.log({ escrow })
    setApp(apps.filter(el => el.escrow === escrow)[0])
    setShowModal(true)
  }

  const isOver = (app) => (now =>
    now > moment.unix(parseInt(app?.endSecs)).unix())(moment().unix())

  const getAppUrl = getAppUrlHelper(providerEnv)

  const getAppSetupUrl = getAppSetupUrlHelper(providerEnv)

  const getApprovalProgram = (type) => {
    switch (type) {
      case 'auction': return approvalProgram
      case 'reverse': return reverseApprovalProgram
      case 'token': return tokenApprovalProgram
    }
  }

  const goToAppSetup = ({ type, appId, program }) => (approvalProgram => program === approvalProgram
    ? history.push(`/sell-${type}/${appId}`)
    : (url => window.open(url, '_blank'))
      (getAppSetupUrl(program, type, appId)))
    (getApprovalProgram(type))

  const goToApp = ({ type, appId, app }) => (approvalProgram => app.program === approvalProgram
    ? history.push(`/${type}/${appId}`)
    : (approvalProgram => (url => window.open(url, '_blank'))
      (getAppUrl(approvalProgram, type, appId)))
      (app.program))
    (getApprovalProgram(type))

  const buttons = <>
    <Button onClick={() => setState({ ...state, label: "ALL", filter: (el) => el.status !== 'open' })}>ALL</Button>
    <Button onClick={() => setState({
      ...state,
      label: "LIVE",
      filter: (now => ({ status, app }) => true
        && status === "live"
        && now <= moment.unix(parseInt(app?.endSecs)).unix())(moment().unix())
    })}>LIVE</Button>
    <Button onClick={() => setState({
      ...state,
      label: "FOR SALE",
      filter: filterForSale
    })}>FOR SALE</Button>
    <Button onClick={() => setState({
      ...state,
      label: "OPEN",
      filter: filterOpen,
    })}>OPEN</Button>
    {false && <Button onClick={() => setState({
      ...state,
      label: "CLOSED",
      filter: ({ status }) => status === "closed"
    })}>CLOSED</Button>}
    {false && <Button onClick={() => setState({
      ...state,
      label: "SOLD",
      filter: ({ sold }) => sold
    })}>SOLD</Button>}
    {false && <Button onClick={() => setState({
      ...state,
      label: "MY AUCTIONS",
      filter: ({ auctioneer }) => auctioneer === acc.address
    })}>MY AUCTIONS</Button>}
    {false && <Button onClick={() => setState({ ...state, filter: ({ auctioneer, status }) => status === "live" && auctioneer[0] === acc.address })}>LIVE</Button>}
    {false && <Button onClick={() => setState({ ...state, filter: ({ auctioneer, status }) => status === "closed" && auctioneer[0] === acc.address })}>CLOSED</Button>}
    {false && <Button onClick={() => setState({ ...state, label: "BIDDING", filter: ({ auctioneer, status, history }) => status === "live" && history.slice(0, -3).map(({ sender }) => sender).includes(acc.address) })}>BIDDING</Button>}
    <Button onClick={handleMore}><RefreshIcon /></Button>
    {feature.browse.add.visible && <>
    {!addLoading
      ? <Dropdown className="d-inline mx-2">
        <Dropdown.Toggle as={Button}>
          <AddIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu variant="dark">
          {feature.browse.add.auction && <Dropdown.Item href="#" onClick={() => {
            setAddLoading(true)
            auctionService.create({ addr: acc.address })
              .then((res) => {
                console.log({ res })
                let { id } = res
                if (id > 0)
                  history.push(`/sell-auction/${id}`)
              })
              .catch(e => {
                console.dir(e)
                setAddLoading(false)
              })
          }}>Auction</Dropdown.Item>}
          {feature.browse.add.token && <Dropdown.Item href="#" onClick={() => {
            setAddLoading(true)
            tokenService.create({ addr: acc.address })
              .then((res) => {
                console.log({ res })
                let { id } = res
                if (id > 0)
                  history.push(`/sell-token/${id}`)
              })
              .catch(e => {
                console.dir(e)
                setAddLoading(false)
              })
          }}>Auction (ASA)</Dropdown.Item>}
          {feature.browse.add.reverse && <Dropdown.Item href="#" onClick={() => {
            setAddLoading(true)
            reverseService.create({ addr: acc.address })
              .then((res) => {
                let { id } = res
                if (id > 0)
                  history.push(`/sell-reverse/${id}`)
              })
              .catch(e => {
                console.dir(e)
                setAddLoading(false)
              })
          }}>Reverse Auction</Dropdown.Item>}
          {feature.browse.add.offer && <Dropdown.Item href="#">Offer</Dropdown.Item>}
        </Dropdown.Menu>
      </Dropdown> : <Button>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
          style={{ "height": "12px", "width": "12px" }}
        />&nbsp;
      </Button>}
      </>}
  </>
  const colProps = {
    xs: 6
  }
  const filteredApps = apps ? apps?.filter(state.filter) : []
  /*
  if (filteredApps && filteredApps.length > 1) {
    colProps.sm = apps.length > 1 ? 6 : 12
  }
  */
  if (filteredApps && filteredApps.length > 3) {
    colProps.md = apps.length > 3 ? 4 : 6
  }
  /*
  if (filteredApps && filteredApps.length > 4) {
    colProps.lg = apps.length > 4 ? 3 : 4
  }
  */
  /*
  if (filteredApps && filteredApps.length > 5) {
    colProps.xl = apps.length > 5 ? 3 : 2
  }
  */
  return <div id="browse" style={auctionStyle}>
    <BasicModal open={showModal} handleOpen={() => setShowModal(true)} handleClose={() => setShowModal(false)}>
      <Row>
        <Col xs={12}>
          <Image src={app?.image} fluid />
        </Col>
        <Col xs={12}>
          <Row>
            <Col xs={12} sm={6}>
              <span>APP ID:</span>
              <a style={{ float: 'right' }} href={`${explorer}/application/${app?.appId}`} target="_blank" rel="noopener noreferrer">{app?.appId}</a>
            </Col>
            <Col xs={12} sm={6}>
              <span>ASSET ID:</span>
              <a style={{ float: 'right' }} href={`${explorer}/asset/${app?.assetId}`} target="_blank" rel="noopener noreferrer">{app?.assetId}</a>
            </Col>
            <Col xs={12} sm={6}>
              <span>ESCROW:</span>
              <a style={{ float: 'right' }} href={`${explorer}/address/${app?.escrow}`} target="_blank" rel="noopener noreferrer">{formatCompactAddress(app?.escrow)}</a>
            </Col>
            <Col xs={12} sm={6}>
              <span>CREATOR:</span>
              <a style={{ float: 'right' }} href={`${explorer}/address/${app?.asset?.creator}`} target="_blank" rel="noopener noreferrer">{formatCompactAddress(app?.asset?.creator)}</a>
            </Col>
            {false && <Col xs={6}>
              <span>OWNER:</span><a style={{ float: "right" }} href={`${explorer}/address/${app?.status === "live" ? app?.auctioneer : app?.receiver}`} target="_blank" rel="noopener noreferrer">{formatCompactAddress(app?.status === "live" ? app?.auctioneer : app?.receiver)}</a>
            </Col>}
            {app?.endSecs && <Col xs={12}>
              <span>END:</span>
              <span style={{ float: "right" }}>{moment.unix(app?.endSecs).calendar()}</span>
            </Col>}
          </Row>
        </Col>
        {(history => <>
          <Col xs={12}>
            {history?.length > 0 && <span>BID HISTORY:</span>}
          </Col>
          <Col className="browse-bid-history" xs={12} style={{ height: "150px", overflow: "scroll" }}>
            <Table className="w-100" borderless striped>
              <tbody>
                {history.map(({ amount, sender, roundTime }, i) =>
                  <tr>
                    <td>#{history?.length - i}</td>
                    <td><a href={`${explorer}/address/${sender}`} target="_blank" rel="noopener noreferrer">{formatCompactAddress(sender)}</a></td>
                    <td style={{ textAlign: "right" }}><Icon size={12} name="algo" />&nbsp;{(amount / 1000000).toFixed(2)}</td>
                    <td style={{ textAlign: "right" }}>{moment.unix(roundTime).format("LTS")}</td>
                  </tr>)}
              </tbody>
            </Table>
          </Col>
        </>)((app?.history ?? []).slice(0, -3))}
        {app?.status === 'live' &&
          <Col>
            {app?.assetId === 333321830 // GoGoats Nature #16
              ? <>
                <a href={`https://app2.nftjam.net/auction/427207800`} target="_blank">JOIN AUCTION</a><br />
                <span class="text-danger">window opens to older version of app</span>
              </>
              : moment().unix() < moment.unix(app?.app?.endSecs).unix() && <a style={{ float: "right" }} href={`/auction/${app?.addr}`} target="_blank">JOIN AUCTION</a>}
          </Col>}
      </Row>
    </BasicModal >
    {buttons}
    {
      apps ? <>
        <div style={{
          ...auctionTitleStyle,
          "fontWeight": "100",
          "color": "white",
          "marginTop": "100px",
        }}>
          {state.label || "ALL"} {apps.filter(state.filter)
            .filter(el => el?.asset?.name !== "Yieldly") // TODO: maybe move this somewhere else
            .length}
        </div>
        <Row>
          {apps
            .filter(state.filter)
            .filter(el => el?.asset?.name !== "Yieldly") // TODO: maybe move this somewhere else
            .map((el, i) =>
              <Col className="mt-4"
                key={el.appId}
                {...colProps}
                style={{
                }}>
                <div className="mb-4">
                  <Badge bg="secondary" onClick={() => setState({ ...state, label: "ALL", filter: el => el.status !== 'open' })}>all</Badge>{' '}
                  {el.type === "auction"
                    ? <Badge bg="primary" onClick={() => setState({ ...state, label: "AUCTION", filter: el => el['type'] === 'auction' && el.status !== 'open' })}>{el.type}</Badge>
                    : el.type ==="token"
                      ? <Badge bg="warning" onClick={() => setState({ ...state, label: "TOKEN", filter: el => el['type'] === 'token' && el.status !== 'open' })}>{el.type}</Badge>
                      : <Badge bg="warning" onClick={() => setState({ ...state, label: "REVERSE", filter: el => el['type'] === 'reverse' && el.status !== 'open' })}>{el.type}</Badge>}{' '}
                  {el.status === 'live'
                    ? (isOver(el.app)
                      ? <Badge bg="info" onClick={() => setState({ ...state, label: "FOR SALE", filter: filterForSale })}>sale</Badge>
                      : <Badge bg="success">{el.status}</Badge>)
                    : el.status === 'open'
                      ? <Badge bg="danger" onClick={() => setState({ ...state, label: "OPEN", filter: filterOpen })}>open</Badge>
                      : <Badge bg="danger">{el.status}</Badge>}
                </div>
                {el.status !== 'open'
                  ? <BrowseCard {...el} onClick={handleCardClick}>
                    <JoinButton onClick={() => goToApp(el)} />
                  </BrowseCard>
                  : <AddCircleIcon fontSize="large" style={{ cursor: "pointer" }} onClick={() => goToAppSetup(el)} />}
                {false && <>
                  {el.type === 'auction'
                    ? <BrowseCard acc={acc} stdlib={stdlib} {...el} {...el.app} index={i} onClick={handleCardClick} onUpdate={handleMore}>
                      {moment().unix() > moment.unix(parseInt(el.app.endSecs)).unix()
                        ? <JoinButton label={<span style={{ fill: "white" }}>Buy NFT <Icon size="10" name="algo" />&nbsp;{el.app.reservePrice}</span>} onClick={() => goToApp(el)} />
                        : <JoinButton onClick={() => history.push(`/${el.type}/${el.appId}`)} />}
                      {/*moment().unix() > moment.unix(parseInt(el.app.endSecs)).unix()
                    ? <BuyButton onClick={() => handleBuy(acc, auctionBackend, el.appId, el.assetId, el.app.reservePrice)} {...el.app} />*/}
                    </BrowseCard>
                    : <BrowseCard acc={acc} stdlib={stdlib} {...el} {...el.app} index={i} onClick={handleCardClick} onUpdate={handleMore}>
                      {el.type !== 'closed' && <JoinButton onClick={() => history.push(`/${el.type}/${el.appId}`)} />}
                    </BrowseCard>}
                </>}
              </Col>
            )}

        </Row>
      </> :
        <Row>
          {[...Array(20)].map((el, i) =>
            <Col key={i} className="mt-4" xs={6} md={4} >
              <PlaceholderCard />
            </Col>)}
        </Row>
    }
    {apps2 && <Row>
      {apps2.map((el, i) => <Col key={el.id} className="mt-4" xs={6} md={4} >
        {el.image
          ? <BrowseCard {...el} >
            {el.program === approvalProgram
              ? <BidButton />
              : <JoinButton />}
          </BrowseCard>
          : <>
            <PlaceholderCard />
            <div onClick={() => {
              goToAppSetup({ type: "auction", ...el })
            }}>
              {el.id}<br />
              {formatCompactAddress(el.addr)}<br />
              {el.program}
            </div>
          </>}
        {false && <a onClick={async () => {
          let { transactions } = (await getAppTransactions(el.id))?.data
          if (transactions.length === 2) {
            history.push(`/sell-auction/${el.id}`)
          } else {
            history.push(`/auction/${el.id}`)
          }
        }}>{el.id}</a>}

      </Col>)}
    </Row>}
  </div >
}

export default Browse