import { createContext } from "react"
export const features = {
  production: {
    browse: {
      add: {
        visible: true,
        auction: true,
        token: true,
        reverse: true,
        offer: true,
      }
    },
    navbar: {
      sell: false
    },
    auctionSearch: false,
    buyPage: false,
    sellPage: false,
    browsePage: true,
    myAuctions: false,
    deleteButton: false,
  },
  test: {
    browse: {
      add: {
        visible: true,
        auction: true,
        token: true,
        reverse: true,
        offer: true,
      }
    },
    navbar: {
      sell: false
    },
    auctionSearch: false,
    buyPage: false,
    sellPage: true,
    browsePage: true,
    myAuctions: false,
    deleteButton: false,
  },
  dev: {
    browse: {
      add: {
        visible: true,
        auction: true,
        token: true,
        reverse: true,
        offer: false,
      }
    },
    navbar: {
      sell: false
    },
    auctionSearch: false,
    buyPage: false,
    sellPage: true,
    browsePage: true,
    myAuctions: false,
    deleteButton: false,
  }
}
export const FeatureFlagContext = createContext(features.dev)